import styled from '@emotion/styled';

export const ModalButton = styled.div`
display: flex;
height: 24px;
padding: 6px 12px;
justify-content: center;
align-items: center;
gap: 6px;
border-radius: 6px;
background: #EDF2F7;
cursor: pointer;
color: #1A202C;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 16px;
margin-left: 12px;

img {
  width: 14px;
}
`