import dayjs from "dayjs";
import {useEffect, useRef} from "react";
// import {RoleType} from "./enum";


export const addCommas = (number) => {
  return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '0'
}

export const numberOnly = (e) => {
  if(e.target.value.match(/[^0-9]/g)) {
    e.target.value =  e.target.value.replace(/[^0-9]/g, '');
  }
}

export const stringNumberToInt = (stringNumber) => {
  return stringNumber?
      parseInt(stringNumber.toString().replace(/,/g , ''))
      : 0;
}

/* 주민등록번호 혹은 외국인등록번호로 현제 나이 계산 */
export const idNumToCurrentOld = (idNum) => {
  let birthDay = '';
  if (idNum) {

    birthDay = idNum.slice(0, 6)

    //주민등록 번호 뒷자리 시작 숫자
    const idNumBackStartNum = idNum.slice(7, 8);
    if( idNumBackStartNum === '1' || idNumBackStartNum === '5' ||
        idNumBackStartNum === '2' || idNumBackStartNum === '6' )  {
      //19xx년생 (등록번호 뒷자리 1,5,2,6 시작)
      birthDay = '19'+birthDay;
    } else {
       //20xx년생 (3,7,4,8 시작)
      birthDay = '20'+birthDay;
    }
    let old = dayjs().format('YYYY') - dayjs(birthDay).format('YYYY')
    return old? old < 0? 0 : old : 0;
  }
}

const useDidMountEffect = (func: any, deps: any) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) func()
    else{ didMount.current = true; }
  }, deps);
};
export default useDidMountEffect;

export const checkPasswordRule = (password) => {
  return !/^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,15}$/.test(password)
}

// export const checkRole = (menuUrl, role, checkType) => {
//   if (!role?.admin) {
//     let findRole;
//     if ( checkType == RoleType.READ) {
//       findRole = role.readRole.find( e => e.menu_url === menuUrl)
//     } else if (checkType == RoleType.WRITE) {
//       findRole = role.writeRole.find( e => e.menu_url === menuUrl)
//     }
//     if (!findRole) {
//       alert('해당 매뉴에 권한이 없습니다. 관리자에게 문의해주세요')
//       return false;
//     }
//   }
//   return menuUrl;
// }
