import {createBrowserRouter, createHashRouter, RouterProvider} from 'react-router-dom';

import { routes } from './app-routes.js';

const router = createHashRouter(routes);

function App() {
  return (<RouterProvider router={router} />)
}

export default App;