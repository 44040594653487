import { useEffect } from 'react';
import { Outlet, useNavigate, useLocation, useSearchParams } from 'react-router-dom';

export function AppLayout() {

  const navigate = useNavigate()
  const location = useLocation()
  const [params, setParams] = useSearchParams()

  useEffect(() => {
    // let token = params.get('token')
    //
    // if (token) {
    //
    //   localStorage.setItem('admin_token', token)
    //
    //   params.delete('token')
    //   setParams(params)
    //
    // } else {
    //
    //   token = localStorage.getItem('admin_token')
    //
    // }

    // if ( location.pathname !== '/empty' ) navigate('/empty')

  }, [location.pathname])

  return (
    <div>
      <div>
        <Outlet />
      </div>
    </div>
  );
}