import { AppLayout } from './app-layout.js'
import { Noti } from './pages/noti.js'
import { Notis } from './pages/notis.js'
import { PayStub } from './pages/payStub.js'
import {Empty} from "./pages/empty";

const routes = [
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        path: '/',
        element: <Empty />
      },
      {
        path: '/pay-stub',
        element: <PayStub />
      }
      // {
      //   path: '/noti/:siteId',
      //   element: <Notis />
      // },
      // {
      //   path: '/noti/:siteId/v/:faqId',
      //   element: <Noti />
      // }
    ]
  },
  {
    path: '/empty',
    element: <Empty />
  }

];

export { routes };