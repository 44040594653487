import styled from '@emotion/styled';
import ReactModal from 'react-modal';
import { ModalButton } from './ModalButton';
import {useWindowSize} from 'react-use';

const customStyles = {
  overlay: {
    position: 'fixed',
    zIndex: '9998',
    background: 'rgba(0,0,0,0.4)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  content: {
    position: 'relative',
    marginTop: '50px',
    maxWidth: '100%',
    width: '60%',
    height: '30%',
    maxHeight: '90%',
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 'auto',
    border: 'none',
    overflow: 'auto',
    outline: 'none',
  },
};

function PasswordModal({ children, opend, closeModal, header, buttons, okText, cancelText = '취소', handleOk, widthCheck}) {

  const { width } = useWindowSize()

  return (
  <ReactModal
    isOpen={opend}
    onRequestClose={closeModal}
    shouldCloseOnOverlayClick={false}
    style={{
      overlay: customStyles.overlay,
      content: {
        ...customStyles.content,
        width: widthCheck,
        ...(width < 992 ? {
          alignContents: 'center',
          marginTop: 0,
          width: '85%',
          maxWidth: '100%',
          // height: '40%',
          height: '280px',
          maxHeight: '300px'
        }: {})
      }
    }}
    ariaHideApp={false}
  >
    <Padding>
      <Header>

      { width < 992 ?
      <>
        <div>
          {/*<SidebarButton onClick={closeModal} alt='bars' src='/assets/icons/chevron-left.svg'/>*/}
        </div>
        <div>
          {typeof header === 'string' ? <h4 style={{ color: '#171923', fontSize: '18px', margin: 0}}>{header}</h4> : header}
        </div>
        <div></div>
      </>
      :
        <>
          <div>
            {typeof header === 'string' ? <h4 style={{ color: '#171923', fontSize: '18px', margin: 0}}>{header}</h4> : header}
          </div>

          <div></div>

          {/*<ButtonGroup>*/}
          {/*  {buttons}*/}

          {/*  <ModalButton onClick={closeModal}>*/}
          {/*    <img src='assets/icons/left-icon.svg' />*/}
          {/*  </ModalButton>*/}
          {/*</ButtonGroup>*/}
        </>
      }

      </Header>

      <Content>
        {children}
      </Content>

      <Footer>
        <div>
        </div>

        {/*<ButtonGroup>*/}
        {/*  {*/}
        {/*    // cancelText !== '' ? <ModalButton onClick={closeModal}>{cancelText}</ModalButton> : <div></div>*/}
        {/*  }*/}
        {/*  <ModalButton */}
        {/*    style={{ color: '#fff', background: '#3279F5'}}*/}
        {/*    onClick={() => {*/}
        {/*      handleOk()*/}
        {/*      // closeModal()*/}
        {/*    }}*/}
        {/*  >{okText}</ModalButton>*/}
        {/*</ButtonGroup>*/}
      </Footer>
    </Padding>
  </ReactModal>
  )
}

const Padding = styled.div`
display: flex;
flex-direction: column;
height: 100%;
`

const Header = styled.div`
display: flex;
  //width: 100%;
//justify-content: space-between;
//align-items: center;
margin-bottom: 24px;
padding: 16px 0px;

& > div {
  width: 33.3%;

  &:nth-child(2) {
    text-align: center;
  }
}
`

const Content = styled.div`
height: 100%;
overflow-y: auto;
padding: 0 16px;
`

const ButtonGroup = styled.div`
display: flex;
justify-content: flex-end;
align-items: center;
`

const Footer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
padding: 16px 24px;
`

const SidebarButton = styled.img`
cursor: pointer;
`

export default PasswordModal