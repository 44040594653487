import styled from '@emotion/styled'
import axios from 'axios'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { HOST_URL } from '../shared/env'

export function Notis() {

  const [params, setParams] = useSearchParams()
  const { siteId } = useParams()

  const navigate = useNavigate()

  const [notis, setNotis] = useState([])

  useEffect(() => {

    ( async () => {

      const { data: { result } } = await axios.post(HOST_URL + '/board/faqList', {
        lang: 'ko',
        uuid: 'string',
        token: params.get('token') || localStorage.getItem('admin_token'),
        site_seq: siteId,
        faq_type: 'noti',
        search_txt: '',
        page: 1,
        limit: 0
      })

      setNotis(result.FaqList.map((e) => ({
        ...e,
        crt_dt: dayjs(e.crt_dt.replace('Z', '')).format('YYYY-MM-DD HH:mm'),
      })))

    })()

  }, [siteId])  

  return (<Container>

    <Header>
      <HeaderTitle>공지사항</HeaderTitle>
      {/* <HeaderContent>
        <div></div>
        <DeleteLabel>전체삭제 <Icon src='/icon/trash.svg' /></DeleteLabel>
      </HeaderContent> */}
    </Header>

    <Ul>

      {notis.map((e, i) => 
        <Li key={i} onClick={() => navigate(`/noti/${siteId}/v/${e.faq_seq}`)}>
          <div>
            <div>
              {/* <Label>{'공지'}</Label> */}
              <strong>{e.subject}</strong>
            </div>
            <DateText>
              {e.crt_dt}
            </DateText>
          </div>

          <div>
            <Icon src='/icon/right-arrow.svg' />
          </div>
        </Li>
      )}

    </Ul>

  </Container>)
}

const Container = styled.div``

const Header = styled.div`
margin-bottom: 10px;
height: 58px;
`

const HeaderTitle = styled.div`
color: #363636;
font-size: 16px;
font-weight: 600;
`

const HeaderContent = styled.div`
display: flex;
justify-content: space-between;
`

const DeleteLabel = styled.div`
cursor: pointer;
display: flex;
border-radius: 24px;
border: 1px solid #E5F2F1;
color: #038175;
padding: 6px 10px;
`

const Icon = styled.img`
width: 18px;
`

const Ul = styled.div``

const Li = styled.div`
cursor: pointer;
display: flex;
justify-content: space-between;
align-items: center;
&:not(:last-child) {
  border-bottom: 1px solid #E7E7E7;
  padding-bottom: 12px;
  margin-bottom: 12px;
}
`

const Label = styled.span`
border-radius: 4px;
padding: 2px 4px;
margin-right: 4px;
border: 1px solid #E7E7E7;
display: inline-block;
`

const DateText = styled.div`
color: #9C9C9C;
font-size: 14px;
margin-top: 8px;
`