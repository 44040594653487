import styled from '@emotion/styled'
import axios from 'axios'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { HOST_URL } from '../shared/env'

export function Noti() {

  const navigate = useNavigate()

  const { siteId, faqId } = useParams()

  const [noti, setNoti] = useState({})

  useEffect(() => {

    ( async () => {

      const { data: { result } } = await axios.post(HOST_URL + '/board/faqInfo', {
        lang: 'ko',
        uuid: 'string',
        token: localStorage.getItem('admin_token'),
        site_seq: siteId,
        faq_type: 'noti',
        faq_seq: faqId
      })

      setNoti({
        ...result,
        crt_dt: dayjs(result.crt_dt.replace('Z', '')).format('YYYY-MM-DD HH:mm')
      })

    })()

  }, [siteId, faqId])  

  return (
  <Container>

    <Header>
      <Icon src='/icon/left-arrow.svg' onClick={() => navigate(`/noti/${siteId}`)} />
    </Header>

    <div>
      {/* <Label>{'공지'}</Label> */}
      <strong>{noti.subject}</strong>
    </div>
    <DateText>{noti.crt_dt}</DateText>

    <Hr />

    <Content>
      {noti.content}
    </Content>
  </Container>)
}

const Container = styled.div``

const Header = styled.div`
margin-bottom: 10px;
height: 58px;
`

const Icon = styled.img`
cursor: pointer;
width: 18px;
padding: 10px 20px 20px 0;
`

const Label = styled.span`
border-radius: 4px;
padding: 2px 4px;
margin-right: 4px;
border: 1px solid #E7E7E7;
display: inline-block;
`

const DateText = styled.div`
color: #9C9C9C;
font-size: 14px;
margin-top: 8px;
`

const Hr = styled.hr`
border: .5px solid #E7E7E7;
margin-top: 12px;
margin-bottom: 18px;
`

const Content = styled.div`
color: #363636;
font-size: 18px;
font-weight: 300;
line-height: 160%;
white-space: pre-wrap;
word-break: break-word;
`