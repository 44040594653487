import axios from 'axios';
import {HOST_URL} from "../shared/env";

const axiosInstance = axios.create({
  baseURL: HOST_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});
export default axiosInstance;
