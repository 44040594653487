import styled from '@emotion/styled'
import {useNavigate, useSearchParams} from "react-router-dom";
import {useCallback, useEffect, useState} from "react";
import axiosInstance from "../library/axios-index";
import {addCommas, numberOnly} from "../shared/common";
import dayjs from "dayjs";
import PasswordModal from "../compontents/PasswordModal";

const columnNameStyle = {
    width: '25%',
    backgroundColor: '#F2F2F2',
}

export function PayStub() {

    const [params, setParams] = useSearchParams();
    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    const [salaryAmountAndTex, setSalaryAmountAndTex] = useState(null);
    const [attendCountAndWorkingHour, setAttendCountAndWorkingHour] = useState(null);
    const [payStubLoad, setPayStubLoad] = useState(false)
    const [openPasswordModal, setOpenPasswordModal] = useState(true);


    useEffect(() => {
        const param = params.get('key')

        if ( !param ) {
            alert('데이터가 입력되지 않았습니다.')
            navigate('/');
        }
    },[])
    useEffect(() => {
        if ( payStubLoad ) {
            (async () => {
                try {
                    const { data: { result } } = await axiosInstance.post(`/webview/pay-stub`, {
                        key: params.get('key')
                    });
                    setUser(result.user);
                    setAttendCountAndWorkingHour(result.attendCountAndWorkingHour);
                    setSalaryAmountAndTex(result.salaryAmountAndTax);
                    window.flutter_inappwebview?.callHandler('success', {message: '정상적으로 조회되었습니다.'});
                } catch (e) {
                    alert(e.response?.data?.message);
                    window.flutter_inappwebview?.callHandler('error', {message: e.response?.data?.message});
                    navigate('/');
                }
            })()
        }
    }, [payStubLoad])

    const passwordCheck = useCallback(() => {
        const pwd = document.getElementById('passwordInput');
        handlePasswordCheck(pwd.value);
    }, [])

    const handlePasswordCheck = (password) => {
        if ( password.length != 6) {
            alert('주민등록번호/외국인등록번호 앞자리 6자리를 입력해주세요')
            return;
        }

        (async () => {
            try {
                const { data: { result } } = await axiosInstance.post(`/webview/pay-stub/validate`, {
                    key: params.get('key'),
                    password: password,
                });
                setOpenPasswordModal(false);
                setPayStubLoad(true);
            } catch (e) {
                alert(e.response?.data?.message)
            }
        })()
    }

    return (
        payStubLoad ?
        <Content>
            <Board>
                <BoardHeader>
                    {`${dayjs(user?.work_dt).format('YYYY')}년 ${dayjs(user?.work_dt).format('MM')}월분 급여명세서`}
                </BoardHeader>

                <TableContainer>
                    <hr style={{background:'Black', height:'1px',marginTop: '10px', marginBottom: '20px'}}/>
                    <TableWrapper>
                        <Table>
                            <tr>
                                <th style={columnNameStyle}>
                                    성명
                                </th>
                                <td style={{textAlign: "center"}}>
                                    {user?.user_nm}
                                </td>

                                <th style={columnNameStyle}>
                                    직종
                                </th>
                                <td style={{borderRight: 'none', textAlign:'center'}}>
                                    {user?.job_position}
                                </td>
                            </tr>
                            <tr>
                                <th style={columnNameStyle}>
                                    생년월일
                                </th>
                                <td style={{textAlign: 'center'}}>
                                    {user?.birth_day}
                                </td>

                                <th style={columnNameStyle}>
                                    입사일
                                </th>
                                <td style={{borderRight: 'none', textAlign: 'center'}}>
                                    {user?.join_dt}
                                </td>
                            </tr>
                            <tr>
                                <th style={columnNameStyle} colSpan={1}>
                                    소속
                                </th>
                                <td style={{borderRight: 'none', textAlign:'center'}} colSpan={3}>
                                    {user?.site_nm}
                                </td>
                            </tr>

                        </Table>
                    </TableWrapper>

                </TableContainer>
                <TableContainer>
                    <TableWrapper>
                        <Table>
                            <tr style={{height: 40}}>
                                <th>
                                    지급내역
                                </th>
                                <th style={{ width: "25%" }}>
                                    지급액
                                </th>
                                <th>
                                    공제내역액
                                </th>
                                <th style={{ width: "25%", borderRight: 'none'}}>
                                    공제액
                                </th>
                            </tr>
                            <tr>
                                <th style={columnNameStyle}>
                                    기본급
                                </th>
                                <td>
                                    { addCommas(salaryAmountAndTex?.totalDefaultSalaryAmt)}
                                </td>
                                <th style={columnNameStyle}>
                                    소득세
                                </th>
                                <td style={{borderRight: 'none'}}>
                                    { addCommas(salaryAmountAndTex?.incomeTax)}
                                </td>
                            </tr>
                            <tr>
                                <th style={columnNameStyle}>
                                    연장근로수당
                                </th>
                                <td>
                                    { addCommas(salaryAmountAndTex?.totalNightSalaryAmt)}
                                </td>

                                <th style={columnNameStyle}>
                                    지방소득세
                                </th>
                                <td style={{borderRight: 'none'}}>
                                    { addCommas(salaryAmountAndTex?.localIncomeTax)}
                                </td>
                            </tr>
                            <tr>
                                <th style={columnNameStyle}>
                                    휴일근로수당
                                </th>
                                <td>
                                    { addCommas(salaryAmountAndTex?.totalHolidaySalaryAmt)}
                                </td>
                                <th style={columnNameStyle}>
                                    국민연금
                                </th>
                                <td style={{borderRight: 'none'}}>
                                    { addCommas(salaryAmountAndTex?.nationalPension)}
                                </td>
                            </tr>
                            <tr>
                                <th style={columnNameStyle}></th>
                                <td></td>
                                <th style={columnNameStyle}>
                                    건강보험
                                </th>
                                <td style={{borderRight: 'none'}}>
                                    { addCommas(salaryAmountAndTex?.healthInsurance)}
                                </td>
                            </tr>
                            <tr>
                                <th style={columnNameStyle}></th>
                                <td></td>
                                <th style={columnNameStyle}>
                                    장기요양보험
                                </th>
                                <td style={{borderRight: 'none'}}>
                                    { addCommas(salaryAmountAndTex?.longTermCareInsurance)}
                                </td>
                            </tr>
                            <tr>
                                <th style={columnNameStyle}></th>
                                <td></td>
                                <th style={columnNameStyle}>
                                    고용보험
                                </th>
                                <td style={{borderRight: 'none'}}>
                                    { addCommas(salaryAmountAndTex?.employmentInsurance)}
                                </td>
                            </tr>
                        </Table>
                    </TableWrapper>
                </TableContainer>

                <TableContainer>
                    <TableWrapper>
                        <Table>
                            <tr>
                                <th style={columnNameStyle}>
                                    근로일
                                </th>
                                <td>
                                    {attendCountAndWorkingHour?.totalAttendCount}
                                </td>
                                <th style={columnNameStyle}>
                                    총 근로시간
                                </th>
                                <td style={{borderRight: 'none'}}>
                                    {attendCountAndWorkingHour?.totalWorkingHours}
                                </td>
                            </tr>
                            <tr>
                                <th style={columnNameStyle}>
                                    기본근로시간
                                </th>
                                <td>
                                    {attendCountAndWorkingHour?.totalDefaultWorkingHours}
                                </td>
                                <th style={columnNameStyle}>
                                    휴일근로시간
                                </th>
                                <td style={{borderRight: 'none'}}>
                                    {attendCountAndWorkingHour?.totalHolidayWorkingHours}
                                </td>

                            </tr>
                            <tr>
                                <th style={columnNameStyle}>
                                    연장근로시간
                                </th>
                                <td >
                                    {attendCountAndWorkingHour?.totalNightWorkingHours}
                                </td>

                                <th style={columnNameStyle}>

                                </th>
                                <td style={{borderRight: 'none'}}>

                                </td>
                            </tr>

                        </Table>
                    </TableWrapper>
                </TableContainer>

                <TableContainer>
                    <TableWrapper>
                        <Table>
                            <tr>
                                <th>
                                    지급합계
                                </th>
                                <td>
                                    { addCommas(salaryAmountAndTex?.totalSalaryAmt) }
                                </td>
                                <th rowSpan={2} style={{ borderBottom: 'none', fontSize: '14px', fontWeight:'600'}}>
                                    차인지급액
                                </th>
                                <td rowSpan={2} style={{textAlign: "center", borderRight: 'none', borderBottom: 'none', fontSize: '14px', fontWeight:'600'}}>
                                    { addCommas(salaryAmountAndTex?.totalSalaryAmt - salaryAmountAndTex?.totalTax) }
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    공제합계
                                </th>
                                <td>
                                    { addCommas(salaryAmountAndTex?.totalTax) }
                                </td>
                            </tr>
                        </Table>
                    </TableWrapper>
                </TableContainer>

                <TableContainer>
                    <TableWrapper>
                        <Table>
                            <tr>
                                <th>
                                    항목
                                </th>
                                <th style={{borderRight: 'none'}}>
                                    산출식
                                </th>
                            </tr>
                            <tr>
                                <th style={columnNameStyle}>
                                    기본급
                                </th>
                                <td style={{borderRight: 'none'}}>
                                    기본근로시간 * 통상시급
                                </td>
                            </tr>
                            <tr>
                                <th style={columnNameStyle}>
                                    연장근로수당
                                </th>
                                <td style={{borderRight: 'none'}}>
                                    연장근로시간 * 통상시급
                                </td>
                            </tr>
                            <tr>
                                <th style={columnNameStyle}>
                                    휴일근로수당
                                </th>
                                <td style={{borderRight: 'none'}}>
                                    휴일근로시간 * 통상시급
                                </td>
                            </tr>
                            <tr>
                                <th style={columnNameStyle}>
                                    소득세
                                </th>
                                <td style={{borderRight: 'none'}}>
                                    (일당 - 150,000원) * 2.7%
                                </td>
                            </tr>
                            <tr>
                                <th style={columnNameStyle}>
                                    지방소득세
                                </th>
                                <td style={{borderRight: 'none'}}>
                                    소득세 * 10%
                                </td>
                            </tr>
                            <tr>
                                <th style={columnNameStyle}>
                                    국민연금
                                </th>
                                <td style={{borderRight: 'none'}}>
                                    과세급여 * 4.5%
                                </td>
                            </tr>
                            <tr>
                                <th style={columnNameStyle}>
                                    건강보험
                                </th>
                                <td style={{borderRight: 'none'}}>
                                    과세급여 * 3.545%
                                </td>
                            </tr>
                            <tr>
                                <th style={columnNameStyle}>
                                    장기요양보험
                                </th>
                                <td style={{borderRight: 'none'}}>
                                    건강보험 * 12.95%
                                </td>
                            </tr>
                            <tr>
                                <th style={columnNameStyle}>
                                    고용보험
                                </th>
                                <td style={{borderRight: 'none'}}>
                                    과세급여 * 0.9%
                                </td>
                            </tr>
                        </Table>
                    </TableWrapper>
                    <div style={{marginTop: "20px", textAlign:"right"}}> {user?.corp_nm} </div>
                </TableContainer>

            </Board>
        </Content> :
        <PasswordModal
            opend={openPasswordModal}
            closeModal={() => {
                setOpenPasswordModal(false);
            }}
            header={"비밀번호 입력"}
            okText={"확인"}
            handleOk={() => {}}
            widthCheck={"60%"}
        >
            <div>개인정보 보호를 위해 암호화된 보안 메시지 입니다. 내용을 확인하시려면 주민등록번호/외국인등록번호 앞자리 6자리를 입력해주세요.</div>
            <br/>
            <div style={{display:"flex", flexDirection:"row"}}>
                <Input id={'passwordInput'}
                       style={{width:"85%"}}
                       placeholder='주민등록번호/외국인등록번호 앞자리 6자리'
                       maxLength={6}
                       onKeyDown={(e) => e.key === 'Enter' && handlePasswordCheck(e.target.value)}
                       onChange={(e) => { numberOnly(e)}}
                >
                </Input>
                <Button
                    style={{ color: '#fff', background: '#3279F5', width:'15%'}}
                    onClick={() => {
                        passwordCheck();
                    }}
                >{'확인'}
                </Button>
            </div>
        </PasswordModal>
    )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 992px) {
    flex-direction: row;
  }
`

const Board = styled.div`
  border-radius: 16px;
  background: none;
  width: 100%;
  margin-top: 10px;
  @media only screen and (min-width: 992px) {
    margin-top: 0;
  }
`

const BoardHeader = styled.div`
  margin-bottom: 1px;
  margin-top: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
  font-size: 28px;
  font-weight: 300;
  white-space: nowrap;

  h2, h5 {
    margin: 0;
  }

  h5 {

  }
`


const TableContainer = styled.div`
  padding: 0 0;
  margin: 10px auto 20px;
  width: 100%;

`

const TableWrapper = styled.div`
  border: 1px solid black;
  border-radius: 1px;
  overflow: hidden;
  overflow-x: auto;
`

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  table-layout: fixed;
  word-break: break-all;

  th, td {
    text-align: center;
    padding: 8px;
    font-size: 12px;
    font-weight: 500;
    border-right: 1px solid;
    border-bottom: 1px solid;
    white-space: pre-wrap;
  }

  th {
    background-color: #DDEBF7;
    text-align: center;
    width: 25%
  }

  td {
    background: #fff;
    text-align: right;
    width: 25%;
  }

  tr:last-child td {
    border-bottom: none;
  }
  tr:last-child th {
    border-bottom: none;
  }
`
const Input = styled.input`
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  ::-webkit-inner-spin-button{
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button{
    -webkit-appearance: none;
    margin: 0;
  }
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  //border: 1px;
  outline: none;

  padding: 12px 20px;

  color: #171923;
  leading-trim: both;
  text-edge: cap;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  background: #FFF;
  border: 2px solid #CBD5E0;
  border-radius: 6px;
  margin-bottom: 12px;
  //margin-top: 6px;

  :focus {

  }
`

export const Button = styled.div`
display: flex;
height: 24px;
padding: 10px 20px;
justify-content: center;
align-items: center;
gap: 6px;
border-radius: 6px;
background: #EDF2F7;
cursor: pointer;
color: #1A202C;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 16px;
margin-left: 6px;

img {
  width: 14px;
}
`



